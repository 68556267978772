<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">Categories</h6>

      <b-col cols="12" class="mb-2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mb-3" to="/takeaway/products/categories/new">
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="info" class="mb-3 ml-3" v-if="changedOrder" @click="saveOrder()">
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Save Order</span>
        </b-button>
        <b-alert v-if="moveDirection" variant="warning" style="padding: 10px 20px; width: auto" show>
          {{ moveDirection }}
        </b-alert>
        <table class="table">
          <thead>
            <td width="80px">Thumb</td>
            <td>Name</td>
            <td>Products</td>
            <td>Type</td>
            <td>Status</td>
            <td>Action</td>
          </thead>

          <draggable v-model="items" tag="tbody" class="cursor-move w100" @end="onDragEnd">
            <tr v-for="(item, index) in items" :key="index">
              <td><img v-if="item.image" :src="item.image" class="w80" alt="" /></td>
              <td>
                {{ item.title }}
                <small
                  ><br />
                  <p v-html="item.content"></p
                ></small>
              </td>
              <td>{{ item.products }}</td>
              <td v-if="item.settings == null || JSON.parse(item.settings).type == 0">Standard</td>
              <td v-else>Advanced</td>
              <td v-if="item.is_published">Published</td>
              <td v-else>Draft</td>
              <td>
                <router-link tag="div" :to="'/takeaway/products/categories/' + item.id" class="ml-25 btn btn-primary pull-right"> edit </router-link>
              </td>
            </tr>
          </draggable>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BForm, BFormGroup, BFormInput, BAlert, BRow, BCol, BButton, BFormSelect, BCard, BCardText, BListGroupItem } from "bootstrap-vue";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import _ from "lodash";

export default {
  components: {
    BForm,
    BRow,
    BFormSelect,
    BCol,
    BCard,
    BAlert,
    draggable,
    BListGroupItem,
    BButton,
    BCardText,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  watch: {
    items: {
      handler(newVal, oldVal) {
        if (oldVal.length) this.changedOrder = true;
      },
      deep: true,
    },
  },
  data() {
    return {
      items: [],
      initialPositions: [],
      changedOrder: false,
      moveDirection: "",
    };
  },
  methods: {
    async saveOrder() {
      for (let x = 0; x < this.items.length; x++) {
        if (!this.items[x].content) {
          this.items[x].content = " ";
        }
        await useJwt
          .patch_category(this.items[x].id, {
            position: x,
            title: this.items[x].title,
            meta_title: this.items[x].meta_title,
            slug: this.items[x].slug,
            content: this.items[x].content,
            settings: this.items[x].settings,
            is_published: this.items[x].is_published,
          })

          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Error`,
                icon: "CropIcon",
                variant: "danger",
                text: `We couldn't download your products data from the server.`,
              },
            });
          });
      }

      await useJwt
        .list_categories()
        .then((response) => {
          localStorage.setItem("userCategories", JSON.stringify(response.data.data));
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't download your products data from the server.`,
            },
          });
        });

      let cats = JSON.parse(localStorage.getItem("userCategories"));
      if (cats.length) {
        let position = 0;
        this.items = [];
        for (let x = 0; x < cats.length; x++) {
          this.items.push({
            id: cats[x].id,
            position: position,
            title: cats[x].title,
            image: cats[x].image,
            content: cats[x].content,
            settings: cats[x].settings,
            slug: cats[x].slug,
            is_published: cats[x].is_published,
          });
          position++;
        }
      }
    },
    onDragEnd(evt) {
      const { oldIndex, newIndex } = evt;
      if (newIndex < oldIndex || newIndex > oldIndex) {
        this.moveDirection = "Order has been changed, please click save.";
      } else {
        this.moveDirection = "";
      }
    },
  },
  async beforeCreate() {
    await useJwt
      .list_categories()
      .then((response) => {
        localStorage.setItem("userCategories", JSON.stringify(response.data.data));
      })
      .catch((e) => {
        console.error(e);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't download your products data from the server.`,
          },
        });
      });

    let cats = JSON.parse(localStorage.getItem("userCategories"));
    if (cats.length) {
      let position = 0;
      this.items = [];
      for (let x = 0; x < cats.length; x++) {
        this.items.push({
          id: cats[x].id,
          position: position,
          title: cats[x].title,
          image: cats[x].image,
          settings: cats[x].settings,
          content: cats[x].content,
          slug: cats[x].slug,
          is_published: cats[x].is_published,
          products: 0,
        });

        position++;
      }
    }
    let data;

    if (localStorage.getItem("userProducts")) {
      data = localStorage.getItem("userProducts");
    } else {
      data = await useJwt
        .list_products()
        .then((response) => {
          return response.data.data;
        })
        .catch((e) => {
          console.error(e);
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "CropIcon",
              variant: "danger",
              text: `We couldn't download your products data from the server.`,
            },
          });
        });
    }
    data = JSON.parse(data);
    for (let x = 0; x < data.length; x++) {
      data[x].categories.replace(" ", "");
      if (data[x].categories.split(",").length > 1) {
        for (let y = 0; y < data[x].categories.split(",").length; y++) {
          for (let xx = 0; xx < this.items.length; xx++) {
            if (data[x].categories.split(",")[y] == this.items[xx].id) {
              this.items[xx].products += 1;
            }
          }
        }
      } else {
        for (let xx = 0; xx < this.items.length; xx++) {
          if (data[x].categories == this.items[xx].id) {
            this.items[xx].products += 1;
          }
        }
      }
    }
  },
};
</script>
